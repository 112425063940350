<template>
    <div class="uk-container uk-container-large">
        <!-- Unloading class because originally we only unloaded orders in this place in the app (now loading a purchase receipt) -->
        <ol class="list unload">
            <item
                :key="item.id"
                :expanded-item="item.id"
                :item="item"
                :ride="ride"
                :updating="loading"
                action="load"
                @lineLoadStatusChanged="updateLineLoadStatus($event)"
                class="item-spacing"
            />
            <button class="go-back" @click="goBack">
                <i class="fal fa-chevron-left" />
            </button>
        </ol>
    </div>
</template>

<style src="./LoadUnload.scss" lang="scss">

</style>

<script>
import { apiClient } from '@/api';
import Item          from './Item';
import {
    ITEM_STATUS,
    PURCHASE_RECEIPT_LINE_LOAD_STATUS,
    RIDE_STATUS,
    SINGLE_RIDE_INCLUDES
} from '@/constants';

export default {
    name:       "ItemLoadingView",
    components: { Item },
    data() {
        return {
            item:    this.$route.params.item,
            ride:    this.$route.params.ride,
            loading: null,
        }
    },
    methods:    {
        goBack()
        {
            this.$router.push({
                name: 'item_options_view',
                params: {
                    item:   this.item,
                    itemId: this.item.id,
                    ride:   this.ride
                }
            });
        },
        async unloadAll(item) {
            try {
                this.loading = 'all';

                const response = await apiClient.put(`transport/purchase_receipts/${item.purchaseReceipt.id}/load`);

                this.$set(this.item, 'purchaseReceipt', response.data.data);

            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.loading = null;
            }
        },
        async updateLineLoadStatus({ line, item }) {
            try {
                this.loading = line.id;

                const response = await apiClient.put(
                    `transport/purchase_receipts/${item.purchaseReceipt.id}/lines/${line.id}`,
                    { loadStatus: line.loadStatus }
                );
                const lines = this.item.purchaseReceipt.lines;
                for (let i = 0; i < lines.length; i++) {
                    if (lines[i].id === line.id) {
                        lines.splice(i, 1, response.data.data);
                        break;
                    }
                }
                await this.updateStatuses();
            } catch (e) {
                console.error(e); //eslint-disable-line no-console
                throw e;
            } finally {
                this.loading = null;
            }
        },
        async updateStatuses() {
            if (this.ride.status.code < RIDE_STATUS.IN_TRANSIT) {
                try {
                    const response = await apiClient.put(
                        `transport/transport_rides/${this.ride.id}?include=${SINGLE_RIDE_INCLUDES}`,
                        { status: RIDE_STATUS.IN_TRANSIT }
                    );
                    this.$set(this, 'ride', response.data.data);
                } catch (e) {
                    console.error(e); //eslint-disable-line no-console
                    throw e;
                }
            }
            let allLinesLoaded = true;
            for (const line of this.item.purchaseReceipt.lines) {
                if (line.loadStatus < PURCHASE_RECEIPT_LINE_LOAD_STATUS.LOADED) {
                    allLinesLoaded = false;
                    break;
                }
            }
            if (allLinesLoaded && this.item.itemStatus < ITEM_STATUS.LOADED) {
                const response = await apiClient.put(
                    `transport/items/${this.item.id}`,
                    { status: ITEM_STATUS.LOADED }
                );
                this.$set(this, 'item', response.data.data);

            } else if (! allLinesLoaded && this.item.itemStatus === ITEM_STATUS.LOADED) {
                const response = await apiClient.put(
                    `transport/items/${this.item.id}`,
                    { status: ITEM_STATUS.UNDERWAY }
                );
                this.$set(this, 'item', response.data.data);
            }
        }
    },
};
</script>
